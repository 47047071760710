import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShippingLocation, ShippingLocationFilter, ShippingMethods } from '../../../new-models/us-models/v2';
import { ApiService } from '../../../services';

@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  basePath= '/v2/shipping';

  #apiService = inject(ApiService);

  constructor() { }

  getMethods(): Observable<ShippingMethods> {
    return this.#apiService.get(`${this.basePath}/accounts/methods`)
      .pipe(
        map(resp => resp.data),
      );
  }

  allLocations(params: ShippingLocationFilter): Observable<ShippingLocation[]> {
    return this.#apiService.get(`${this.basePath}/locations`, params)
      .pipe(
        map(resp => resp.data),
      );
  }

  findLocation(id: string): Observable<ShippingLocation> {
    return this.#apiService.get(`${this.basePath}/locations/${id}`)
      .pipe(
        map(resp => resp.data),
      );
  }
}
